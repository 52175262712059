import firebase from "firebase";

// Your web app's Firebase configuration
var firebaseConfig = {
    apiKey: "AIzaSyCnQ79BFvAffdzb8qC8xxlBxjfIlhNmh0A",
    authDomain: "blue-app-3f21e.firebaseapp.com",
    databaseURL: "https://blue-app-3f21e-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "blue-app-3f21e",
    storageBucket: "blue-app-3f21e.appspot.com",
    messagingSenderId: "222162083599",
    appId: "1:222162083599:web:2acf811aa4790e3ea0287a",
    measurementId: "G-16B86RCPY2"
};

// Initialize Firebase
export default firebase.initializeApp(firebaseConfig);