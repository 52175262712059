// import { query as fireQuery } from "firebase/firestore";
import firebase from "../../firebaseInit";
const db = firebase.firestore();
// array in local storage for registered users
// { username: "admin", email: "admin@themesdesign.in", password: "123456" }
export function configureFakeBackend() {

    let realFetch = window.fetch;
    window.fetch = function (url, opts) {

        return new Promise((resolve, reject) => {
            // wrap in timeout to simulate server api call
            setTimeout(async () => {

                // authenticate
                if (url.endsWith('/users/authenticate') && opts.method === 'POST') {
                    // get parameters from post request
                    let params = JSON.parse(opts.body);

                    // find if any user matches login credentials

                    try{
                        // console.log(fireQuery);
                        // console.log("firequery")

                        const docsSnap  = await db.collection("admins").where('email','==',params.email ).get()

                        // const q = fireQuery(
                        //     fireCollection(db, "admins"),
                        //     fireWehere("email", "==",params.email ),
                        //     fireWehere("password", "==",params.password ),
                        //     // where("types", "array-contains", ['Laptop', 'Lenovo', 'Intel']),
                        //     // where("price", "<=", 1000),
                        //   );
                          
                        //   const docsSnap = await getDocs(q);
                          let users = [];
                          docsSnap.forEach((doc) => {
                            users.push(doc.data());
                            // console.log(doc.data());
                          });

                          console.log(users);
                        let filteredUsers = users.filter(user => {
                            return user.email === params.email && user.password === params.password;
                        });
    
                        if (filteredUsers.length) {
                            // if login details are valid return user details and fake jwt token
                            let user = filteredUsers[0];
                            let responseJson = {
                                id: user.id,
                                username: user.username,
                                name: user.name,
                                email: user.email,
                                surname:user.surname,
                                role:user.role,
                                token: '65ad3a1c0342ec1b7e7e7036546c5a9b688ed729'
                            };
                            resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(responseJson)) });
                        } else {
                            // else return error
                            reject('These credentials do not match our records.');
                        }
                    }catch(e){
                        console.error(e.stack);
                        reject('Sorry! That didn\'t work. Check your connection and try again.');
                    }
                    

                    return;
                }

              /*  // get users
                if (url.endsWith('/users') && opts.method === 'GET') {
                    // check for fake auth token in header and return users if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
                        resolve({ ok: true, text: () => Promise.resolve(JSON.stringify(users)) });
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // get user by id
                if (url.match(/\/users\/\d+$/) && opts.method === 'GET') {
                    // check for fake auth token in header and return user if valid, this security is implemented server side in a real application
                    if (opts.headers && opts.headers.Authorization === 'Bearer fake-jwt-token') {
                        // find user by id in users array
                        let urlParts = url.split('/');
                        let id = parseInt(urlParts[urlParts.length - 1]);
                        let matchedUsers = users.filter(user => { return user.id === id; });
                        let user = matchedUsers.length ? matchedUsers[0] : null;

                        // respond 200 OK with user
                        resolve({ ok: true, text: () => JSON.stringify(user) });
                    } else {
                        // return 401 not authorised if token is null or invalid
                        reject('Unauthorised');
                    }

                    return;
                }

                // register user
                if (url.endsWith('/users/register') && opts.method === 'POST') {
                    // get new user object from post body
                    let newUser = JSON.parse(opts.body);
                    // validation
                    let duplicateUser = users.filter(user => { return user.username === newUser.username; }).length;
                    if (duplicateUser) {
                        reject("Username '" + newUser.username + "' is already taken");
                        return;
                    }

                    // save new user
                    newUser.id = users.length ? Math.max(...users.map(user => user.id)) + 1 : 1;
                    users.push(newUser);
                    localStorage.setItem('users', JSON.stringify(users));

                    // respond 200 OK
                    resolve({ ok: true, text: () => Promise.resolve() });

                    return;
                } */

                // pass through any requests not handled above
                realFetch(url, opts).then(response => resolve(response));

            },0);
        });
    }
}